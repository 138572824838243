<template>
    <div v-loading="loadingProducts">
        <el-table :data="parcelProducts" 
            show-summary 
            :summary-method="getSummaries"  
            border stripe>
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="deal_id" :label="$t('message.deal')" width="90">
                <template slot-scope="scope">
                    {{ scope.row.deal_id }}
                </template>      
            </el-table-column>
            <el-table-column prop="name" :label="$t('message.name')">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column prop="quantity" :label="$t('message.quantity')">
                <template slot-scope="scope">
                    {{ scope.row.quantity | formatNumber(1) }}
                </template>
            </el-table-column>
            <el-table-column prop="product_price" :label="$t('message.price')">
                <template slot-scope="scope">
                    {{ scope.row.product_price | formatMoney(1) }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import { formatMoney, formatNumber } from "@/filters/index";


export default {
    name: "parcel_products",
    mixins: [form, drawer],
    props: {
        selectedItem: {
            default: Object
        },
    },
    data() {
        return {
            loadingProducts: false, 
        };
    },
    computed: {
        ...mapGetters({
            parcelProducts: "dealProducts/parcelProducts",
            mode: "MODE"
        }),
        totalProductPrice() {
            if(this.parcelProducts && this.parcelProducts.length > 0){
                return this.parcelProducts.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
            }
            return 0;
        },
        totalQuantity() {
            if(this.parcelProducts && this.parcelProducts.length > 0){
                return this.parcelProducts.reduce((total, product) => parseFloat(total) + (parseFloat(product.quantity) || 0), 0);
            }
            return 0;
        },
    },
    methods: {
        ...mapActions({
            showParcelProducts: "dealProducts/showParcelProducts",
        }),
        afterOpen(){
            this.loadingProducts = true;
            this.showParcelProducts(this.selectedItem.id)
                .then(res => { this.loadingProducts = false; })
                .catch(err => { this.loadingProducts = false; });
        },

        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
            if (index === 1) {
                sums[index] = this.$t('message.total');
                return;
            }
            if (column.label === this.$t('message.quantity')) {
                sums[index] = formatNumber(this.totalQuantity, 1);
                return;
            }
            if (column.label === this.$t('message.price')) {
                sums[index] = formatMoney(this.totalProductPrice, 2);
                return;
            }
            });

            return sums;
        },
        
    },
};
</script>